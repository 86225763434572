<template>
	<div class="mypage_content_wrap">
		<h2>콘텐츠 보관함</h2>
		<div class="tab_wrap">
			<ul>
				<li
					:class="{ active: index == current_btn }"
					v-for="(item, index) in content_title"
					:key="index"
					id="text_list"
				>
					<a @click="btn_active" href="#">{{ item }}</a>
				</li>
			</ul>
		</div>
		<div class="mypage_content_box_wrap">
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>대여보관함</h4>
					<div @click="checked_valid = true" v-if="checked_valid === false" class="edit d_block">편집</div>
					<div v-if="checked_valid === true" class="completion">
						<ul>
							<li v-if="all_ck_valid === false" @click="check_all()">전체선택</li>
							<li v-if="all_ck_valid === true" @click="check_all_cancle()">선택해제</li>
							<li @click="checked_valid = false">편집취소</li>
							<li @click="check_delete()">삭제완료</li>
						</ul>
					</div>
				</div>
				<div v-for="(item, index) in content_list" :key="index" class="mypage_list_box">
					<input v-if="checked_valid === true" id="content_check_test" type="checkbox" />
					<div class="mypage_list_img">
						<img alt="" :src="`${$image_host}/contents/${item.thumb_path}`" />
					</div>
					<ul class="tit_wrap d_none_533">
						<li class="bold">제목</li>
						<li class="bold">사용기한</li>
						<li class="btn">바로보기</li>
					</ul>
					<ul class="info_wrap">
						<li>{{ item.opus_title }}</li>
						<li>{{ item.use_date }}~{{ item.rant_end_date }}</li>
						<li class="btn">바로보기</li>
						<button
							v-if="!buy_ck"
							style="cursor: default"
							type="button"
							class="circle_basic_btn circle_green"
						>
							대여
						</button>
						<button
							v-if="buy_ck"
							style="cursor: default"
							type="button"
							class="circle_basic_btn circle_yellow"
						>
							소장
						</button>
					</ul>
				</div>
				<div v-if="jwt.getToken('id_token')" class="pagenation_wrap">
					<ul class="pagenation">
						<Pagination @changePage="page_active" :page="page" :totalPage="totalPage"></Pagination>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ajax from '@/api/api-manager';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import jwt from '@/api/LocalStorage';
import Pagination from '@/shared-components/Pagination.vue';

const content_title = ref(['대여', '구매']);
const checked_valid = ref(false);
const all_ck_valid = ref(false);
const current_btn = ref(0);
const current_btn_valid = ref(1);
const content_list = ref(null);
const page = ref(null);
const totalPage = ref(null);
const router = useRouter();
const buy_ck = ref(false);

onMounted(() => {
	if (jwt.getToken('id_token')) {
		content_paging();
	}
});

const btn_active = (event) => {
	current_btn.value = event.path[1].__vnode.key;
	current_btn_valid.value = 1;
	const parsing = () => {
		if (event.path[1].__vnode.key === 0) {
			return 'Y';
		} else if (event.path[1].__vnode.key === 1) {
			return 'N';
		}
	};
	if (event.path[1].__vnode.key === 0) {
		buy_ck.value = false;
	} else if (event.path[1].__vnode.key === 1) {
		buy_ck.value = true;
	}
	const form = new FormData();
	form.append('rantYn', parsing());
	form.append('page', current_btn_valid.value);
	ajax('mypage/content_list', form).then((res) => {
		content_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};
const check_delete = () => {
	const checked_data = document.querySelectorAll('#content_check_test');
	Array.from(checked_data).map((p, idx) => {
		if (p.checked) {
			delete_like_list(idx);
		}
	});
};
const delete_like_list = (index) => {
	const form = new FormData();
	form.append('idx', content_list.value[index].buy_idx);

	ajax('mypage/delete_content_list', form).then((res) => {
		content_paging();
		const checked_data = document.querySelectorAll('#content_check_test');
		Array.from(checked_data).map((p, idx) => {
			return (p.checked = false);
		});
	});
};

const check_all = () => {
	const checked_data = document.querySelectorAll('#content_check_test');
	Array.from(checked_data).map((p, idx) => {
		return (p.checked = true), (all_ck_valid.value = true);
	});
};
const check_all_cancle = () => {
	const checked_data = document.querySelectorAll('#content_check_test');
	Array.from(checked_data).map((p, idx) => {
		return (all_ck_valid.value = false), (p.checked = false);
	});
};

const page_active = (event) => {
	current_btn_valid.value = event;
	content_paging();
};

const content_paging = () => {
	const parsing = () => {
		if (current_btn.value === 0) {
			return 'Y';
		} else if (current_btn.value === 1) {
			return 'N';
		}
	};
	const form = new FormData();
	form.append('rantYn', parsing());
	form.append('page', current_btn_valid.value);
	ajax('mypage/content_list', form).then((res) => {
		console.log(res);
		content_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};
</script>
